<template>
  <div class="min-h-screen flex items-center justify-center bg-base-300">
      <div class="card bg-white rounded-xl p-5 max-w-sm text-center  shadow">
          <p class="text-gray-600">
            <strong>
              Unauthorized Access 
            </strong> 
            <br>Please try login using the link from your email.
          </p>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>